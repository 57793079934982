import { default as envOoqn9iUI1YMeta } from "/root/injective/injective-bridge/pages/env.vue?macro=true";
import { default as external_45linko06IYNksviMeta } from "/root/injective/injective-bridge/pages/external-link.vue?macro=true";
import { default as bridgepwzsyPbHnOMeta } from "/root/injective/injective-bridge/pages/index/bridge.vue?macro=true";
import { default as fiatc2gtaHKz3OMeta } from "/root/injective/injective-bridge/pages/index/fiat.vue?macro=true";
import { default as indexqL0iPhKuh4Meta } from "/root/injective/injective-bridge/pages/index/index.vue?macro=true";
import { default as wormhole5kvKG2toZzMeta } from "/root/injective/injective-bridge/pages/index/wormhole.vue?macro=true";
import { default as index0nbpxFRgRxMeta } from "/root/injective/injective-bridge/pages/index.vue?macro=true";
import { default as maintenancemiKWOsLPP8Meta } from "/root/injective/injective-bridge/pages/maintenance.vue?macro=true";
import { default as memes_45bridge8TDa5n2BVDMeta } from "/root/injective/injective-bridge/pages/memes-bridge.vue?macro=true";
import { default as transferMcetEaMTEXMeta } from "/root/injective/injective-bridge/pages/transfer.vue?macro=true";
import { default as whr48Jv0V7qyMeta } from "/root/injective/injective-bridge/pages/wh.vue?macro=true";
import { default as wormhole_45migrationfgdNxur4NJMeta } from "/root/injective/injective-bridge/pages/wormhole-migration.vue?macro=true";
export default [
  {
    name: "env",
    path: "/env",
    meta: envOoqn9iUI1YMeta || {},
    component: () => import("/root/injective/injective-bridge/pages/env.vue").then(m => m.default || m)
  },
  {
    name: "external-link",
    path: "/external-link",
    meta: external_45linko06IYNksviMeta || {},
    component: () => import("/root/injective/injective-bridge/pages/external-link.vue").then(m => m.default || m)
  },
  {
    name: index0nbpxFRgRxMeta?.name,
    path: "/",
    component: () => import("/root/injective/injective-bridge/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-bridge",
    path: "bridge",
    component: () => import("/root/injective/injective-bridge/pages/index/bridge.vue").then(m => m.default || m)
  },
  {
    name: "index-fiat",
    path: "fiat",
    meta: fiatc2gtaHKz3OMeta || {},
    component: () => import("/root/injective/injective-bridge/pages/index/fiat.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "",
    component: () => import("/root/injective/injective-bridge/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "index-wormhole",
    path: "wormhole",
    component: () => import("/root/injective/injective-bridge/pages/index/wormhole.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancemiKWOsLPP8Meta || {},
    component: () => import("/root/injective/injective-bridge/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "memes-bridge",
    path: "/memes-bridge",
    component: () => import("/root/injective/injective-bridge/pages/memes-bridge.vue").then(m => m.default || m)
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/root/injective/injective-bridge/pages/transfer.vue").then(m => m.default || m)
  },
  {
    name: "wh",
    path: "/wh",
    meta: whr48Jv0V7qyMeta || {},
    component: () => import("/root/injective/injective-bridge/pages/wh.vue").then(m => m.default || m)
  },
  {
    name: "wormhole-migration",
    path: "/wormhole-migration",
    component: () => import("/root/injective/injective-bridge/pages/wormhole-migration.vue").then(m => m.default || m)
  }
]